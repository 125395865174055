<template>
  <VModalWidget
    :submit="submit"
    :dismiss="dismiss"
    :loading="loading"
    :submitting="submitting"
    :submit-title="buttonTitle"
  >
    <template v-slot:title>{{ $t('labels.client') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-container fluid grid-list-sm class="pa-0">
          <v-row row>
            <v-col cols="6">
              <v-text-field
                v-model.trim="form.firstname"
                :placeholder="$t('labels.firstname')"
                :error-messages="firstNameErrors"
                required
                @input="$v.form.firstname.$touch()"
                @blur="$v.form.firstname.$touch()"
                v-on:keyup.enter="submit"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.trim="form.lastname"
                :placeholder="$t('labels.lastname')"
                :error-messages="lastNameErrors"
                required
                @input="$v.form.lastname.$touch()"
                @blur="$v.form.lastname.$touch()"
                v-on:keyup.enter="submit"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid grid-list-sm class="pa-0">
          <v-row row>
            <v-col cols="6">
              <DateField
                date
                utc
                :placeholder="$t('labels.birthDate')"
                v-model="form.birthdate"
                field-class="mt-0 pt-0"
              />
            </v-col>
            <v-col cols="6">
              <LanguageField v-model="form.language" field-class="mt-0 pt-0" />
            </v-col>
          </v-row>
        </v-container>

        <PhonesWidget
          :phones="form.contact.phones"
          :update-phones="updatePhones"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
          key="simpl-client-phones"
        />
        <EmailsWidget
          :emails="form.contact.emails"
          :update-emails="updateEmails"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
          key="simpl-client-emails"
        />
        <AddressesWidget
          :addresses="form.contact.addresses"
          :update-addresses="updateAddresses"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
          key="simpl-client-addresses"
        />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import moment from 'moment-timezone'
import pick from 'lodash/pick'
import { mapActions, mapState } from 'vuex'
import { Modal } from '@argon/app/mixins'
import { VModalWidget, BaseForm, FormErrors, LanguageField, DateField } from '@argon/app/components'
import { required } from 'vuelidate/lib/validators'
import { NAMESPACE } from '../store'
import { PhonesWidget, AddressesWidget, EmailsWidget, WidgetErrorMixin } from '../../components'

export default {
  name: 'ModalLeadClient',
  mixins: [WidgetErrorMixin, Modal],
  extends: BaseForm,
  components: {
    VModalWidget,
    FormErrors,
    PhonesWidget,
    AddressesWidget,
    EmailsWidget,
    DateField,
    LanguageField
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required }
    }
  },
  props: {
    clientId: { type: [String, Number], required: true }
  },
  data: () => ({
    loading: true,
    form: {
      lastname: '',
      firstname: '',
      birthdate: moment().format('YYYY-MM-DD'),
      contact: {
        phones: [],
        addresses: [],
        emails: []
      }
    },
    collectedErrors: [],
    submitting: false
  }),
  computed: {
    ...mapState(NAMESPACE, ['clients']),
    isCreate() {
      return this.clientId === 'new'
    },
    buttonTitle() {
      return this.isCreate ? this.$t('labels.create') : this.$t('labels.save')
    },
    firstNameErrors() {
      const errors = []
      if (!this.$v.form.firstname.$dirty) return errors
      !this.$v.form.firstname.required && errors.push(this.$t('required'))
      this.getServerErrors('firstname', errors)
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.form.lastname.$dirty) return errors
      !this.$v.form.lastname.required && errors.push(this.$t('required'))
      this.getServerErrors('lastname', errors)
      return errors
    },
    isValid() {
      return !this.$v.form.$invalid && !this.hasErrors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['addClient', 'editClient']),
    updatePhones(value) {
      this.form.contact.phones = value
    },
    updateAddresses(value) {
      this.form.contact.addresses = value
    },
    updateEmails(value) {
      this.form.contact.emails = value
    },
    submit() {
      let callback = this.isCreate ? this.addClient : this.editClient
      if (!this.isValid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let data = pick(this.form, ['firstname', 'lastname', 'contact', 'birthdate', 'language'])
      let firstNameChanged = this.client && this.client.firstname !== this.form.firstname
      let lasteNameChanged = this.client && this.client.lastname !== this.form.lastname
      if (firstNameChanged || lasteNameChanged) {
        data['firstname'] = this.form.firstname
        data['lastname'] = this.form.lastname
      }
      let payload = this.client ? { clientId: this.client.id, data } : data
      callback(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    if (this.clientId !== 'new') {
      const client = this.clients.find((x) => x.id === this.clientId)
      this.form = { ...client }
    }
    this.loading = false
  }
}
</script>

<style scoped>
.v-text-field {
  padding-top: 0;
  margin-top: 0;
  /* margin-bottom: 20px; */
}
</style>

<i18n>
{
  "en": {
    "cancel": "@:labels.cancel",
    "name": "@:labels.name",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "newTitle": "New Client",
    "editTitle": "Edit Client"
  },
  "fr": {
    "cancel": "@:labels.cancel",
    "name": "@:labels.name",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "newTitle": "Nouveau client",
    "editTitle": "Modifier le client"
  }
}
</i18n>
